import React, { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { httpReauest } from "../utils/httpRequest";
import { Shamsi } from "basic-shamsi";
import { CDN_BASE_URL } from "../config";
import CustomModal from "../components/common/Modal";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { priceComma } from "../utils/priceComma";

const OrdersPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState();
  const [hasmore, sethasmore] = useState(true);
  const [show, setShow] = useState(false);
  const [selected, setselcted] = useState();
  const [step, setstep] = useState(1);
  const [postCode, setpostCode] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setselcted(data);
    setShow(true);
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    const page = products.length / 10 + 1;
    await httpReauest("GET", "/order?page=" + page + "&limit=10", {}, {}).then(
      ({ data }) => {
        if (data?.data?.length < 10) {
          sethasmore(false);
        }
        setProducts(data.data);
      }
    );
    setLoading(false);
  }

  async function fetchData() {
    const page = products.length / 10 + 1;
    await httpReauest("GET", "/order?page=" + page + "&limit=10", {}, {}).then(
      ({ data }) => {
        setProducts((cur) => [...cur, ...data.data]);

        if (data?.data?.length < 10) {
          sethasmore(false);
        }
      }
    );
  }

  async function approve() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/order/approve/" + selected._id,
      {},
      {
        "x-access-token": user.token,
      }
    ).then(({ status }) => {
      if (status === 201) {
        toast.success(
          "سفارش تایید شد و پیامک تایید بزودی به مشتری ارسال میشود"
        );
        getData();
        handleClose();
        setstep(2);
      }
    });
  }

  async function sendOrder() {
    if (!postCode) {
      return toast.error("کد رهگیری خالی است");
    }
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/order/sended/" + selected._id,
      { postCode },
      {
        "x-access-token": user.token,
      }
    ).then(({ status }) => {
      if (status === 201) {
        toast.success("سفارش تغییر وضعیت به ارسال شده شد");
      }
    });
  }
  return (
    <div>
      <CustomModal show={show} onHide={handleClose} fit>
        <div className="flex justify-between gap-4 items-center sm:min-w-[500px] w-full">
          <h3 className="whitespace-nowrap">سفارش #{selected?._id}</h3>
          <button onClick={handleClose}>
            <FaTimes />
          </button>
        </div>
        <div className="mt-3">
          <div className="border rounded-[8px] shadow-md p-2">
            <span className="flex justify-start p-2 gap-1">
              وضعیت سفارش:
              {selected?.status == "تایید نشده" ? (
                <span className="bg-red-500 text-white px-3 rounded-[8px]">
                  تایید نشده
                </span>
              ) : selected?.status == "تایید شده منتظر ارسال" ? (
                <span className="bg-yellow-500 text-white px-3 rounded-[8px]">
                  تایید شده منتظر ارسال
                </span>
              ) : (
                <span className="bg-green-500 text-white px-3 rounded-[8px]">
                  ارسال شده
                </span>
              )}
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>نام و نام خانوادگی :</span>
              <span dir="ltr">{selected?.name}</span>
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>شماره تماس:</span>
              <span dir="ltr">{selected?.phone}</span>
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>استان:</span>
              <span dir="ltr">{selected?.state}</span>
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>شهر:</span>
              <span dir="ltr">{selected?.city}</span>
            </span>{" "}
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>کد پستی:</span>
              <span dir="ltr">{selected?.postalCode}</span>
            </span>{" "}
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>تاریخ سفارش :</span>
              <span dir="ltr">
                {" "}
                {Shamsi.format(
                  "yyyy/MM/dd - hh:mm",
                  new Date(selected?.createdAt)
                )}
              </span>
            </span>
            <span className="p-2 flex">
              <span>آدرس:</span>
              <span dir="ltr">{selected?.address}</span>
            </span>{" "}
            <span className="p-2">
              <span className="font-bold block">صورت حساب:</span>
              <div dir="ltr" className="flex flex-col gap-1 mt-2 border p-2">
                {selected?.products?.map((item) => (
                  <div class=" border-t border-b py-2">
                    <div class="flex items-center justify-between">
                      <img
                        src={
                          item?.images
                            ? CDN_BASE_URL + item?.images[0]
                            : CDN_BASE_URL + item?.product?.images[0]
                        }
                        className="w-20 rounded"
                        alt={item?.name ? item?.name : item?.product?.name}
                      />
                      <p class="text-md  text-right font-medium text-gray-900 my-2">
                        {item?.name ? item?.name : item?.product?.name ? item?.product?.name : "محصول حذف شده"}
                      </p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="font-semibold text-gray-900" dir="rtl">
                        {priceComma(item?.buy_price, 2)} تومان
                      </p>
                      <p class="text-sm font-medium text-gray-900">
                        قیمت محصول
                      </p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="font-semibold text-gray-900">{item.count}</p>
                      <p class="text-sm font-medium text-gray-900">تعداد</p>
                    </div>
                    {item.color && (
                      <div class="flex items-center justify-between">
                        <p class="font-semibold text-gray-900">{item.color}</p>
                        <p class="text-sm font-medium text-gray-900">رنگ</p>
                      </div>
                    )}
                    {item.size && (
                      <div class="flex items-center justify-between">
                        <p class="font-semibold text-gray-900">{item.size}</p>
                        <p class="text-sm font-medium text-gray-900">سایز</p>
                      </div>
                    )}
                  </div>
                ))}
                <div class=" border-t border-b py-2">
                  <div class="flex items-center justify-between">
                    <p class="font-semibold text-gray-900" dir="rtl">
                      {priceComma(selected?.allPrice, 2)} تومان
                    </p>
                    <p class="text-sm font-medium text-gray-900">مجموع</p>
                  </div>
                </div>
              </div>{" "}
              <span className="flex justify-center p-3">
                {step === 1 ? (
                  <button onClick={approve} className="btn-brand">
                    تایید سفارش
                  </button>
                ) : step === 2 ? (
                  <div className="flex gap-2">
                    <span className="relative">
                      <input
                        onChange={(e) => setpostCode(e.target.value)}
                        className="w-full form-brand h-[40px]"
                      />
                      <p className="on-border font-bold">کد رهگیری</p>
                    </span>
                    <button onClick={sendOrder} className="btn-brand">
                      تغییر وضعیت به ارسال شده
                    </button>
                  </div>
                ) : null}
              </span>{" "}
            </span>{" "}
          </div>
        </div>
      </CustomModal>
      <div className="w-full">
        <div className=" mb-10">
          <h1 className="title">سفارشات</h1>
          <p>روند ارسال سفارش به شرح زیر است : </p>
          <p>
            1 : سفارش را کاربر در سایت ثبت میکند و پیامک به ادمین میرود برای
            تایید سفارش و ادمین هم سفارش را چک میکند و تایید میکند
          </p>
          <p>
            2 : پس از تایید سفارش ادمین محصول را به مشخصات پست میکند و شماره
            رهگیری را در همین قسمت وارد میکند به کاربر اطلاع داده میشود شماره
            رهگیری تا بقیه پیگیری ها رو از سمت پست داشته باشه
          </p>
        </div>

        <InfiniteScroll
          dataLength={products?.length ? products?.length : 10} //This is important field to render the next data
          next={fetchData}
          hasMore={hasmore}
          loader={"Loading..."}
        >
          {loading ? (
            <Loading />
          ) : (
            <div className="grid lg:grid-cols-3 gap-3">
              {products?.map((item) => (
                <div className="border rounded-[8px] shadow-md p-2">
                  <span className="flex justify-start p-2 gap-2">
                    وضعیت سفارش:
                    {item?.status == "تایید نشده" ? (
                      <span className="bg-red-500 text-white px-3 rounded-[8px]">
                        تایید نشده
                      </span>
                    ) : item?.status == "تایید شده منتظر ارسال" ? (
                      <span className="bg-yellow-500 text-white px-3 rounded-[8px]">
                        تایید شده منتظر ارسال
                      </span>
                    ) : (
                      <span className="bg-green-500 text-white px-3 rounded-[8px]">
                        ارسال شده
                      </span>
                    )}
                  </span>
                  <span className="p-2 flex justify-between flex-wrap gap-3">
                    <span>نام و نام خانوادگی :</span>
                    <span dir="ltr">{item.name}</span>
                  </span>
                  <span className="p-2 flex justify-between flex-wrap gap-3">
                    <span>مجموع قیمت:</span>
                    <span dir="ltr">{priceComma(item?.allPrice, 2)} تومان</span>
                  </span>
                  <span className="p-2 flex justify-between flex-wrap gap-3">
                    <span>شماره تماس:</span>
                    <span dir="ltr">{item.phone}</span>
                  </span>
                  <span className="p-2 flex justify-between flex-wrap gap-3">
                    <span>استان:</span>
                    <span dir="ltr">{item.state}</span>
                  </span>
                  <span className="p-2 flex justify-between flex-wrap gap-3">
                    <span>شهر:</span>
                    <span dir="ltr">{item.city}</span>
                  </span>{" "}
                  <span className="p-2 flex justify-between flex-wrap gap-3">
                    <span>کد پستی:</span>
                    <span dir="ltr">{item.postalCode}</span>
                  </span>{" "}
                  <span className="p-2 flex justify-between flex-wrap gap-3">
                    <span>تاریخ سفارش :</span>
                    <span dir="ltr">
                      {" "}
                      {Shamsi.format(
                        "yyyy/MM/dd - hh:mm",
                        new Date(item.createdAt)
                      )}
                    </span>
                  </span>
                  <span className="p-2 flex">
                    <span>آدرس:</span>
                    <span dir="ltr">{item.address}</span>
                  </span>{" "}
                  <span className="flex justify-center p-3">
                    <button
                      onClick={() => {
                        if (item.status === "تایید شده منتظر ارسال") {
                          setstep(2);
                        }
                        if (item.status === "ارسال شده") {
                          setstep(3);
                        }
                        handleShow(item);
                      }}
                      className="btn-brand"
                    >
                      مشاهده جزییات
                    </button>
                  </span>
                </div>
              ))}
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default OrdersPage;
