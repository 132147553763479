import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";

const EditProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [vehicles, setVehicles] = useState();
  const [selectedVehicle, setselectedVehicle] = useState();
  const [loading, setloading] = useState();
  const [single, setsingle] = useState("notselect");
  const [products, setproducts] = useState([
    { color: "", colorCode: "", size: "", balance: "" },
  ]);
  function getFileName(filePath) {
    // جدا کردن مسیر با استفاده از '/' و '\' و سپس گرفتن آخرین بخش
    const parts = filePath.split(/[/\\]/);
    const fileNameWithExtension = parts.pop(); // آخرین بخش را می‌گیرد

    // حذف شماره‌ها و نگه‌داشتن فقط نام فایل و پسوند
    const fileName = fileNameWithExtension.replace(/-.*(?=\.)/, ""); // حذف همه چیز بعد از '-' و قبل از '.'

    return fileName;
  }

  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => {
        // بررسی وضعیت پاسخ
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const file = new File([blob], getFileName(url), {
              type: blob.type || "application/octet-stream", // نوع MIME پیش‌فرض
            });
            resolve(file);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  };

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      category: [],
      balance: "",
      height: "",
      size: "",
      price: "",
      special_price: "",
      url: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      if (typeof formik.values.price === "string") {
        const newPrice = Number(values.price?.replaceAll(",", ""));
        formdata.append("price", newPrice);
      } else {
        formdata.append("price", values.price);
      }
      if (values.special_price) {
        if (typeof formik.values.price === "string") {
          const newSpecialPrice = Number(
            values.special_price?.replaceAll(",", "")
          );
          formdata.append("special_price", newSpecialPrice);
        } else {
          formdata.append("special_price", values.special_price);
        }
      }
      formdata.append("name", values.name);
      formdata.append("height", values.height);
      formdata.append("url", values.url);
      formdata.append("category", JSON.stringify(values.category));
      formdata.append("desc", values.desc);

      if (single === "true") {
        formdata.append("single", true);
        formdata.append("size", values.size);
        formdata.append("balance", values.balance);
      } else {
        formdata.append("products", JSON.stringify(products));
      }

      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/product/" + selectedVehicle?._id, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول تغییر یافت");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setselectedVehicle(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
        } else {
          setloading(false);
          toast.error(dat);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    setloading(true);

    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });

    await httpReauest("GET", "/product/names/dash", {}, {}).then(({ data }) => {
      setVehicles(data?.data);
    });
    setloading(false);
  }

  async function handleChange(e) {
    setImages([]);
    setPreview([]);
    const { data } = await httpReauest(
      "GET",
      "/product/" + e.target.value,
      {},
      {}
    );

    const newVehicle = data?.data?.product;
    formik.setFieldValue("name", newVehicle.name);
    formik.setFieldValue("desc", newVehicle.desc);
    formik.setFieldValue("category", newVehicle.category);
    formik.setFieldValue("price", newVehicle.price);
    formik.setFieldValue("special_price", newVehicle.special_price);
    formik.setFieldValue("url", newVehicle.url);
    formik.setFieldValue("height", newVehicle.height);
    if (data?.data?.vehicle?.single) {
      setsingle("true");
      formik.setFieldValue("size", newVehicle.size);
      formik.setFieldValue("balance", newVehicle.balance);
    } else {
      setsingle("false");
      setproducts(data?.data?.subproducts);
    }

    setselectedVehicle(newVehicle);

    newVehicle?.images?.map((e) => {
      toDataURL(CDN_BASE_URL + e)
        .then((file) => {
          setImages((current) => [...current, file]);
        })
        .catch((e) => {
          console.log(e);
        });
      setPreview((current) => [...current, CDN_BASE_URL + e]);
    });
  }

  async function handleDelete(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    await fetch(BASE_URL + "/product/" + selectedVehicle?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول حذف شد");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  function handleDeleteCategory(_id) {
    const filtered = formik.values.category.filter((item) => item !== _id);
    formik.setFieldValue("category", filtered);
  }

  function handleAddCategory(_id) {
    if (formik.values.category.includes(_id) || _id == "" || !_id) {
      return;
    }
    const filtered = [...formik.values.category, _id];
    formik.setFieldValue("category", filtered);
  }

  function handleChangeProduct(value, fild, index) {
    setproducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index] = {
        ...updatedProducts[index],
        [fild]: value,
      };
      return updatedProducts;
    });
  }

  function handleChangePrice(params) {
    const value = params.target.value;
    const formattedValue = (
      Number(value.replace(/\D/g, "")) || ""
    ).toLocaleString();
    formik.setFieldValue("price", formattedValue);
  }

  function handleChangeSpecialPrice(params) {
    const value = params.target.value;
    const formattedValue = (
      Number(value.replace(/\D/g, "")) || ""
    ).toLocaleString();
    formik.setFieldValue("special_price", formattedValue);
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="vehicle"
            name="vehicle"
            onChange={handleChange}
            className={` w-full form-brand h-[48px]`}
          >
            <option>{loading && <Loading />}</option>
            {vehicles?.map((e) => {
              return <option value={e.url}>{e.name}</option>;
            })}
          </select>
          <p className="on-border font-bold">انتخاب محصول*</p>
        </span>
        {selectedVehicle && (
          <>
            <>
              <span className="relative">
                <input
                  placeholder="مثلا : دستبند النگویی"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full form-brand h-[48px]"
                />
                {formik.errors.name && formik.touched.name && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {formik.errors.name}
                  </small>
                )}
                <p className="on-border font-bold">نام محصول*</p>
              </span>
              <span className="relative">
                <input
                  id="url"
                  name="url"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full form-brand h-[48px]"
                />
                <p className="on-border font-bold">url*</p>
              </span>
              <span className="relative">
                <input
                  id="price"
                  name="price"
                  value={formik.values.price}
                  onChange={handleChangePrice}
                  onBlur={formik.handleBlur}
                  className="w-full form-brand h-[48px]"
                />
                {formik.errors.price && formik.touched.price && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {formik.errors.price}
                  </small>
                )}
                <p className="absolute translate-y-[-50%] top-[24px] left-4">
                  تومان
                </p>
                <p className="on-border font-bold">قیمت محصول*</p>
              </span>

              <span className="relative">
                <input
                  id="special_price"
                  name="special_price"
                  value={formik.values.special_price}
                  onChange={handleChangeSpecialPrice}
                  onBlur={formik.handleBlur}
                  className="w-full form-brand h-[48px]"
                />
                {formik.errors.special_price &&
                  formik.touched.special_price && (
                    <small style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.special_price}
                    </small>
                  )}
                <p className="absolute translate-y-[-50%] top-[24px] left-4">
                  تومان
                </p>
                <p className="on-border font-bold">
                  قیمت با تخفیف(در صورتی که تخفیف دارد)
                </p>
              </span>

              <span className="relative">
                <select
                  id="category"
                  name="category"
                  onChange={(e) => handleAddCategory(e.target.value)}
                  className={` w-full form-brand h-[48px]`}
                >
                  <option></option>
                  {categorys?.map((e) => (
                    <option value={e._id}>{e.name}</option>
                  ))}
                </select>
                {formik.errors.category && formik.touched.category && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {formik.errors.category}
                  </small>
                )}
                <p className="on-border font-bold">دسته بندی*</p>
                <span>دسته بندی های موجود:</span>
                {categorys?.map((cat) =>
                  formik.values.category.map((item) => {
                    if (item == cat._id) {
                      return (
                        <div className="flex justify-between items-center my-0.5">
                          <span>{cat.name}</span>
                          <span>
                            <FaTrash
                              onClick={() => handleDeleteCategory(item)}
                              className="cursor-pointer"
                              color="red"
                            />
                          </span>
                        </div>
                      );
                    }
                  })
                )}
              </span>
              <span className="relative">
                <input
                  placeholder="مثلا : 100cm"
                  id="height"
                  name="height"
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  className="w-full form-brand h-[48px]"
                />
                <p className="on-border font-bold">قد محصول*</p>
              </span>
              {single === "true" && (
                <>
                  <span className="relative">
                    <input
                      placeholder="مثلا : 10"
                      id="balance"
                      name="balance"
                      value={formik.values.balance}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="number"
                      className="w-full form-brand h-[48px]"
                    />
                    <p className="on-border font-bold">تعداد*</p>
                  </span>
                  <span className="relative">
                    <input
                      placeholder="مثلا : فری 36 تا 42"
                      id="size"
                      name="size"
                      value={formik.values.size}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      className="w-full form-brand h-[48px]"
                    />
                    <p className="on-border font-bold">سایز*</p>
                  </span>
                </>
              )}

              {single === "false" && (
                <>
                  {products?.map((item, index) => (
                    <span className="col-span-full grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
                      <h3 className="col-span-full text-[22px] font-bold">
                        محصول {index + 1}
                      </h3>
                      <span className="relative">
                        <input
                          id={`color[${index}]`}
                          name={`color[${index}]`}
                          value={products[index].color}
                          onChange={(e) =>
                            handleChangeProduct(e.target.value, "color", index)
                          }
                          type="text"
                          className="w-full form-brand h-[48px]"
                        />
                        <p className="on-border font-bold">اسم رنگ*</p>
                      </span>
                      <span className="relative">
                        <input
                          id={`colorCode[${index}]`}
                          name={`colorCode[${index}]`}
                          value={products[index].colorCode}
                          onChange={(e) =>
                            handleChangeProduct(
                              e.target.value,
                              "colorCode",
                              index
                            )
                          }
                          onBlur={formik.handleBlur}
                          type="color"
                          className="w-full form-brand h-[48px]"
                        />
                        <p className="on-border font-bold">کد رنگی*</p>
                      </span>
                      <span className="relative">
                        <input
                          id={`balance[${index}]`}
                          name={`balance[${index}]`}
                          value={products[index].balance}
                          onChange={(e) =>
                            handleChangeProduct(
                              e.target.value,
                              "balance",
                              index
                            )
                          }
                          onBlur={formik.handleBlur}
                          type="text"
                          className="w-full form-brand h-[48px]"
                        />
                        <p className="on-border font-bold">تعداد*</p>
                      </span>
                      <span className="relative">
                        <input
                          id={`size[${index}]`}
                          name={`size[${index}]`}
                          value={products[index].size}
                          onChange={(e) =>
                            handleChangeProduct(e.target.value, "size", index)
                          }
                          onBlur={formik.handleBlur}
                          type="text"
                          className="w-full form-brand h-[48px]"
                        />
                        <p className="on-border font-bold">سایز*</p>
                      </span>
                    </span>
                  ))}
                  <span className="flex col-span-full gap-2 items-center">
                    <FaPlus
                      className="cursor-pointer"
                      onClick={() => {
                        setproducts((cur) => [
                          ...cur,
                          { color: "", colorCode: "", size: "", balance: "" },
                        ]);
                      }}
                      color="black"
                      size={32}
                    />
                    {products?.length > 1 && (
                      <FaTrash
                        onClick={() =>
                          setproducts((prevProducts) =>
                            prevProducts.filter(
                              (_, i) => i !== products.length - 1
                            )
                          )
                        }
                        className="cursor-pointer"
                        color="red"
                        size={32}
                      />
                    )}
                  </span>
                </>
              )}

              <span dir="ltr" className="col-span-full">
                <h2 className="title text-right">:توضیح</h2>
                <ReactQuill
                  value={formik.values.desc}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false], font: ["Opens Sans"] }, {}],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      ["link", "image", "code"],
                      ["clean"],
                    ],
                  }}
                  formats={formats}
                  onChange={(e) => formik.setFieldValue("desc", e)}
                />
              </span>

              <div className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
                {previews?.length > 0 ? (
                  <div
                    className="p-3"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "15px",
                    }}
                  >
                    {previews &&
                      previews.map((src, i) => {
                        return (
                          <span className="relative">
                            <img
                              src={src}
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "contain",
                              }}
                              alt=""
                            />
                            <FaTrash
                              color="red"
                              className="top-0 right-0 absolute"
                              onClick={() => deleteImg(i)}
                            />
                          </span>
                        );
                      })}
                    <label htmlFor="images" className="cursor-pointer">
                      <FaPlus size={50} />
                    </label>
                  </div>
                ) : (
                  <label htmlFor="images" className="cursor-pointer">
                    <FaPlus className="center" size={50} />
                  </label>
                )}

                <p className="on-border font-bold">تصاویر</p>
              </div>
              <input
                onChange={(e) => {
                  handleImages(e);
                }}
                type="file"
                className="hidden"
                id="images"
                accept="image/png, image/jpg, image/jpeg"
              />
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button type="submit" className="btn-brand">
                    ارسال
                  </button>
                  <button type="button" onClick={handleDelete} className="btn-brand">
                    حذف محصول
                  </button>
                </>
              )}
            </>
          </>
        )}
      </form>
    </div>
  );
};

export default EditProductForm;
