import React from "react";
import Navbar from "./Navbar";
import { FaAlignJustify } from "react-icons/fa";

const Layout = ({ children, home, admin }) => {
  return (
    <>
      <div dir="rtl" className="grid grid-cols-12 relative overflow-x-hidden">
        <span className="col-span-full lg:col-span-2 ">
          <Navbar admin={admin} />
        </span>
        <span className="col-span-full lg:col-span-10 p-3 lg:p-5 mt-10 lg:mt-0">
          {children}
        </span>
      </div>
    </>
  );
};

export default Layout;
