import "./App.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Layout from "./components/layout";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "swiper/css";
import LoginPage from "./pages/LoginPage";
import { useEffect, useState } from "react";
import { httpReauest } from "./utils/httpRequest";
import ProductManagePage from "./pages/ProductManagePage";
import BrandManagePage from "./pages/BrandManagePage";
import BlogManagePage from "./pages/BlogManagePage";
import "react-quill/dist/quill.snow.css";
import AdminManagePage from "./pages/AdminManagePage";
import Chat from "./pages/Chat";
import { initiateSocketConnection } from "./helpers/socketHelper";
import StoryManagePage from "./pages/StoryManagePage";
import CategoryManagePage from "./pages/CategoryManagePage";
import NotificationManagePage from "./pages/NotificationManagePage";
import OrdersPage from "./pages/OrdersPage";

initiateSocketConnection();

function App() {
  useEffect(() => {
    getData();
  }, []);

  const [admin, setAdmin] = useState();

  async function getData() {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data) {
      await httpReauest(
        "POST",
        "/admin/islogined",
        {},
        { "x-auth-token": data?.token }
      )
        .then(({ data }) => {
          if (window.location.href == window.origin + "/") {
            window.location.href = window.origin + "/dashboard";
          }
          setAdmin(data?.data);
        })
        .catch(() => {
          window.location.href = window.origin + "/";
          localStorage.removeItem("user");
        });
    } else {
      if (window.location.href != window.origin + "/") {
        window.location.href = window.origin + "/";
      }
    }
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <LoginPage />
        </>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <>
          <Layout admin={admin}>
            <ProductManagePage />
          </Layout>
        </>
      ),
    },
    {
      path: "/category",
      element: (
        <>
          <Layout admin={admin}>
            <CategoryManagePage />
          </Layout>
        </>
      ),
    },
    {
      path: "/orders",
      element: (
        <>
          <Layout admin={admin}>
            <OrdersPage />
          </Layout>
        </>
      ),
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
